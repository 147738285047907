.footer{
    background-color: black;
    color: white;
    padding: 30px;
    text-align: left;
}

.footer__links{
    margin: 50px 0px;
}

.footer__links a{
    color: white;
    margin: 20px;
}

.footer__disclosure{
    font-size: 12px;
}