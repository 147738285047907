.about{
    background-color: white;
    color: black;
    padding: 10%;
    font-size: 14px;
    text-align: left;
}

.about__header{
    font-size: 30pt;
    font-weight: bold;
}