*{
  box-sizing: border-box;
}
.App {
  text-align: center;
}

.clear{
  clear:both;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 20000; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0); /* Black fallback color */
  background-color: rgba(0, 0, 0, 0.2); /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.modal {
  z-index: 30000;
  left: 50%;
  margin-left: calc(440px / -2);
  top: 10%;
  background: #fff;
  border-radius: 10px;
  padding: 2%;
  position: relative;
  width: 400px;
  position: fixed !important;
  padding: 50px;
}

.blur{
  filter: blur(10px);
}

.btn{
  padding: 10px 15px;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  transition: .5s all;
  border: none;
}

.btn--primary{
  background-color: #23a6d5;
  color: white;
}

.btn--primary:hover{
  background-color: mediumpurple;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
