.header{
    background-color: white;
    color: black;
    text-align: left;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__logo{
    color: white;
    fill: white;
    vertical-align: middle;
    background-color: black;
    padding: 5px 30px;
    border-radius: 30px;
}

.header__logo > span{
}

.header__logo img{

}

.header__logo-name{
    vertical-align: middle;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
}

.header__logo-name a{
    color: white;
    text-decoration: none;
}

.header__links{

}

.header__links a{
    color: black;
    text-decoration: none;
    font-weight: bold;
    margin:0px 10px;
}