body {
	/* background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
	background-size: 400% 400%;
	animation: gradient 15s ease infinite; */
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.home{
    background-color: #f3f3f3;
}

.home__blurb{
    padding: 20px;
    background-color: white;
    color: black;
    border-radius: 10px;
    width: 60%;
    margin: auto;
    text-align: left;
    /* animation: textColor 10s ease infinite; */
}

.home__blurb-signature{
    margin-top: 10px;
    font-size: 12px;
    font-weight: normal;
}

.home__disclosure{
    font-size: 11px;
    color: #444;
    width: 60%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.home__more-stuff{
    padding: 5%;
    color: white;
    font-size:30px;
    font-family: 'Oswald';
}

  @keyframes animateGlow {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes textColor {
    0% {
      color: #7e0fff;
    }
    50% {
      color: #0fffc1;
    }
    100% {
      color: #7e0fff;
    }
  }

.home__hero{
    padding: 20px;
    padding: 5px 20%;
    text-align: left;
}

.home__hero-title{
    font-size: 50px;
    font-weight: bold;
}

.home__hero-blurb{
    font-family: 'Oswald', sans-serif;
}

.home__hero-blurb svg{
    color: white;
    fill: white;
}

.home__hero-blurb-large{
    font-size: 60px;
}

.home__hero-blurb-small{
    font-size: 30px;
}

.home__hero-feature-product{
    flex: 1;
}

.home__categories{
    text-align: center;
    padding: 30px;
}

.home__category{
    display: inline-block;
    margin: 10px;
    color: white;
    cursor: pointer;
}

.home__products{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 80%;
    margin: auto;
}

.home__product{
    background-color: white;
    color: black;
    margin: 2px;
    width: 30%;
    border:solid 3px;
    overflow:hidden;
    position: relative;
    border-radius: 10px;
    border: none;
    box-shadow: 2px 2px 9px #ddd;
    border-radius: 20px;
    margin: 10px;
}

.home__product-name{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.2;
    display: none;
}

.home__product-category{
    padding: 5px 20px;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    position:absolute;
    border-radius: 30px;
    background-color: white;
    color: black;
}

.home__product-description{
    margin-top: 20px;
    display: none;
}

.home__product-thumbnail{
    overflow: hidden;
    cursor: pointer;
    height: 400px;
}

.home__product-thumbnail img{
    height: 100%;
    transition: .5s all;
}



.home__product-btn{
    margin-top: 50px;
    position: absolute;
    bottom: 0px;
    left: 0px;
}

.home__product-btn .btn--primary{
    padding: 10px 35px;
    font-weight: bold;
    border-radius: 10px;
    background-color: black;
    color: white;
    border-radius: 30px;
    border:solid 3px white;
    padding:5px 30px;
}

.modal__product_wrapper{
    width: 100%;
margin-left: 0px;
left: 0px;
top: 0px;
height: 100%;
width: 90%;
height: 90%;
top: 5%;
left: 5%;
}

.modal__product{
    display: flex;
    overflow: auto;
    height: 80%;
}

.modal__product-thumbnail{
    max-width: 50%;
}

.modal__product-thumbnail img{
    width:100%;
}

.modal__product-details{
    padding-left: 20px;
    height: 100%;
    overflow: auto;
    width: 50%;
}

.modal__product-details-name{
    font-size: 30px;
    font-weight: bold;
    font-family: 'Oswald';
}

.modal__product-details-description{
    line-height: 1.6;
    padding: 5%;
    text-align: left;
}

.modal__product-details-buttons{
    margin-top: 40px;
}

.modal__product-close{
    margin-top: 40px;
}

.home__sidepanel{
    position: fixed;
    height: 100%;
    width: 30%;
    background-color: #f3f3f3;
    padding: 20px;
    top: 0px;
    right: -30%;
    transition: .5s all;
}

.home__sidepanel--show{
    right: 0px;
}

@media screen and (max-width: 890px){
    .home__products{
        flex-direction: column;
    }

    .home__product{
        width: 80%;
        margin: 5% 10%;
    }

    .modal__product_wrapper{
        width:90%;
        margin-left: unset;
        left: 5%;
    }

    .modal__product{
        flex-direction: column;

    }

    .home__blurb{
        width: 80%;
    }

    .home__disclosure{
        width: 80%;
    }
}